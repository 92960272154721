import React from 'react';
import styled from 'styled-components';

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  /* z-axis: -1; */
  height: 100vh;
  width: 100vw;
  /* border: 4px solid red; */
  img {
    object-fit: cover;
    object-position: center top;
  }
`;

export default function Backdrop() {
  return (
    <StyledBackground>
      <img
        alt="background image"
        src="https://cdn.sanity.io/images/kz42dfs4/production/1fcf8615d8467b3783a501336d548ae846939174-4000x2507.jpg?w=3000&q=60"
        style={{ height: '100vh', width: '100vw' }}
      />
    </StyledBackground>
  );
}
