import React from 'react';

import styled from 'styled-components';
import DownButton from './DownButton';

// import BGimage from './../../../../static/temp/bgtrans.png';
import { useMeasure } from 'react-use';

const StyledIntro = styled.div`
  min-height: calc(100vh - 78px);
  color: white;
  display: flex;
  align-items: center;
  > div {
    margin: 2em;
    max-width: 80em;
    padding: 1em;
    text-align: center;
    width: 100%;
    margin: auto;
    * > {
    }
    h1 {
      filter: blur(0);
      position: relative;
      z-index: 30;

      img {
        max-width: 10em;
      }
    }
    p {
      font-size: 1.2em;
      position: relative;
      z-index: 30;
      max-width: 500px;
      margin: auto;
      margin-bottom: 1.5em;
    }

    .backgroundBlur {
      filter: blur(50px);
      /* filter: blur(5px); */
      border-radius: 50%;
      width: 400px;
      height: 200px;
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 90px;
      left: calc(50% - 200px);
      margin: auto;
      z-index: 1;
      overflow: hidden;

      @media (max-width: 500px) {
        width: 100%;
      }
    }
    .wrap {
      position: relative;
    }
  }
`;

export default function IntroBlock({ headerHeight }) {
  const [lippy, { height: containerHeight }] = useMeasure();

  return (
    <StyledIntro ref={lippy}>
      <div>
        {/* <h1>My hurtigruten moment</h1> */}
        <div className="wrap">
          <div className="backgroundBlur"></div>
        </div>
        <h1>
          <img src="https://cdn.sanity.io/images/4kn9ntmr/production/f93a5324d5e80369f9295881d9894907b207ad9b-856x432.png?w=1024&fit=max" />
        </h1>
        <p>
          We invited you to share your best Hurtigruten memory and the response was overwhelming.
          Here is a small sample of all the memories we have received.
        </p>
        <p>Thank you all for your heart-warming and awesome contributions.</p>
        <DownButton elementHeight={containerHeight} />
      </div>
    </StyledIntro>
  );
}
