import React from 'react';
import stories from './stories';
import Backdrop from './Backdrop';
import Header from './Header';
import StoryContainer from './StoryContainer';


export default function App(props) {
  return (
    <>
      <Backdrop />
      <StoryContainer stories={stories} headerHeight='76' />
      <Header />
    </>
  );
}
