export default [
  {
    _id: 'abcd112',
    author: 'Ian Strudwick',
    content:
      'Kayaking amongst the icebergs in Disko Bay Greenland was the greatest experience I have ever had. I have the photos taken by Stian and Tess that I treasure and use as my wallpaper on my iPad. Every time I open my iPad, I relive this special memory and takes me back.',
  },
  {
    _id: 'abcd1',
    author: 'Martin Edwards',
    content:
      'My 2017 cruise was very special as for the first time in 70 years I was visiting the birthplace of my grandfather and found the gravestone of my Great Grandparents in Kabelvåg. As I stood on deck until Lofoten disappeared below the horizon my heart yearned to return. Now I know a part of me lives in that place.',
  },
  {
    _id: 'abcd12',
    author: 'Allan Jones',
    content:
      "Two penguins waddled over and tapped my shoulder to say hello. I hadn't come from the sea so I wasn't a seal; I hadn't flown so I wasn't a skua; therefore, I must be a penguin. Then others brought pebbles as gifts. Some brought their young families until, surrounded by the whole colony, for an hour I really was a penguin.",
  },
  {
    _id: 'abcd13',
    author: 'Sherran Arstall (Mrs)',
    content:
      'In January/February 2018 I took my late husband’s ashes on his last voyage. As I scattered his ashes whilst at the stern of MS Trollfjord, the Borealis appeared and took him on his final journey.',
  },
  {
    _id: 'abcd14',
    author: 'Lynne Cooper',
    content:
      'Crossing the Arctic Circle on my 50th Birthday and then being baptised with icy water – champagne then helped warm me up.',
  },
  {
    _id: 'abcd15',
    author: 'Abderrahman Cheikh',
    content:
      'My strongest memory was during a night when we arrived in a zone with one of the worst storms ever. The captain said it was his first time since he started working on that ship! Most passengers went early to their cabin while I tried to keep balance in the saloon. Once again please.',
  },
  {
    _id: 'abcd16',
    author: 'John Leech',
    content:
      'Having never seen the Northern Lights before except on films or photographs, to see it live was truly mesmerising and astonishing. It was made more exciting because of the enthusiasm shown by the staff rushing around the ship to make sure everyone had the chance to see this natural wonder.',
  },
  {
    _id: 'abcd17',
    author: 'Lilla Clark',
    content:
      "My greatest memory was watching an eagle fly alongside the ship and keeping pace for several minutes. Perhaps he was as intrigued by us as we were by him. It's lovely that the captain tells you when there are things like this to see, and where to look.",
  },
  {
    _id: 'abcd18',
    author: 'Jan Arent',
    content:
      'When you travel along the Norwegian coast, you lose your pettiness, your narrow-mindedness and your bad mood. Most people need this!',
  },
  {
    _id: 'abcd19',
    author: 'Magnus Valland',
    content:
      'As a regular guest on Hurtigruten, using it for its original purpose, shipping locals and goods along the Norwegian Coast, I have this beautiful memory of the sunrise on upper deck, after a delicious breakfast, on the northbound passing the Stad peninsula. The calm and secure movement, with rays blending my eyes. ',
  },
  {
    _id: 'abcd110',
    author: 'Joyce Simpson',
    content:
      '13th March 2018, South Pacific Ocean. On my first expedition cruise with Hurtigruten from Valparaiso to Costa Rica. A huge dolphin pod swam and leapt in front of MS Fram for several minutes to the complete delight of all passengers and crew, who had never seen such numbers. ',
  },

  {
    _id: 'abcd111',
    author: 'Palle Waage Jensen',
    content:
      'In 2016 my wife and I made the Hurtigruten tour Bergen-Kirkenes and back. On the way we passed some small islands with a stone heap on them, and I jokingly said "Look, a submarine. In the Trollfjord, a real submarine emerged with the crew on top. At first, she wouldn\'t believe it!',
  },

  {
    _id: 'abcd113',
    author: 'Åse-Karin Sæthre',
    content:
      'In July 1955 I was almost 12 years old and traveled for the first time with Hurtigruten, old Polarlys, from Bergen to Stamsund and back. I traveled alone, everything was an experience and not least I have good memories from the captain, 2nd mate and a of the ladies in the northbound restaurant who showed me so much consideration. ',
  },
  {
    _id: 'abcd114',
    author: 'Robert Whitehorn ',
    content:
      'Riding along the beach in the Lofoten islands at 2 a.m. and seeing such a huge sparkling sky to the sound of the sea and horse’s hooves.',
  },
  {
    _id: 'abcd115',
    author: 'Gunnel Berlin',
    content:
      'On board, between the beautiful mountains of Vesterålen, to see the eagle coming closer, hanging above us waiting for the moment when one of the crew throws a big cod up in the air. And then, with graced perfection, the eagle grabs the cod with both claws, high above us, turns around and sails away towards the nest. ',
  },
  {
    _id: 'abcd116',
    author: 'Richard King',
    content:
      'When they gave us the bar bill!!! So many happy memories from our three trips with you.',
  },
  {
    _id: 'abcd117',
    author: 'Marjorie Taylor',
    content:
      'The most beautiful boat MS Trollfjord alongside the beautiful scenery, and then meeting the Sami in their tent for reindeer soup ',
  },
  {
    _id: 'abcd118',
    author: 'Gunnar Eilif Knudsen',
    content:
      'Hurtigruten takes infection control seriously! If you do not take care of yourself, others will. A great afternoon in Kristiansund 4 September 2021 on MS Kong Harald. ',
  },
  {
    _id: 'abcd119',
    author: 'Annabelle Partington',
    content:
      'When I stepped onto the Antarctic continent for the first time. I’ve never experienced such an untamed place. The weather, scenery and wildlife were spectacular and it’s a moment I will treasure forever (the smell of penguin poo… not so much).',
  },
  {
    _id: 'abcd1120',
    author: 'Barbara Greenwood',
    content:
      'Crossing the Arctic Circle on our 40th wedding anniversary, although I did not enjoy Neptune putting ice down my neck!',
  },
];
