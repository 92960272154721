import React, { useState, useRef } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';
import DownButton from './DownButton';

const StoryStyle = styled.div`
  // Calc, height minus the header
  /* min-height: calc(100vh - 75px); */
  /* min-height: 50vh; */
  min-height: 500px;
  display: flex;
  align-items: start;
  align-items: center;
  justify-content: center;
  font-family: 'Atlas Grotesk';
  font-weight: 400;
  /* border: 1px solid goldenrod; */
  .story {
    background: white;
    margin: 2em;
    max-width: 40em;
    padding: 1.5em 3em 2.5em;
    padding: 2.5em;
    flex-grow: 0;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    .byline {
      text-align: right;
      font-style: italic;
      margin: 0;
      margin-top: 1em;
      padding: 0;
      span {
        font-weight: 600;
      }
    }
    .content {
      margin: 0;
      line-height: 1.4;
    }
    svg {
      margin-bottom: 10px;
    }
  }

  .downs {
    display: flex;
    justify-content: center;
  }

  .cta {
    margin-top: 20vh;
    margin-bottom: 20vh;
  }
  .book-button {
    padding: 1em 2em;
    color: white;
    font-family: 'Atlas Grotesk';
    font-weight: 700;
    background: #d40000;
    border: none;
    margin-top: 6px;
    display: inline-block;
    text-decoration: none;
  }
`;

function Horizontal() {
  return (
    <div
      style={{
        margin: 'auto',
        padding: 0,
        textAlign: 'center',
        borderBottom: '1px solid #999',
        maxWidth: '16em',
      }}
    ></div>
  );
}

export default function Story({
  story,
  isLast,
  stepFunction,
  accumulateFunction,
  resetHeights,
  index,
}) {
  // const [lastHeight, setLastHeight] = useState();

  const thisParticularElement = useRef();

  function clickFunction() {
    console.log(thisParticularElement.current);
    thisParticularElement.current.scrollIntoView({ behavior: 'smooth' });
  }

  // const thisRef = 'theRef' + index;

  return (
    <StoryStyle ref={thisParticularElement}>
      <div>
        <div className="story">
          {/* <div> */}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35.28"
            height="23.64"
            viewBox="0 0 35.28 23.64"
          >
            <path
              id="Path_80"
              data-name="Path 80"
              d="M167.04,14.2l-.12-1.08c-8.64,2.52-18.36,8.28-18.36,17.04a6.348,6.348,0,0,0,6.24,6.6c3.96,0,6.84-2.52,6.84-6.12,0-3-1.56-6.24-6-6.72a4.235,4.235,0,0,1,.36-1.68C157.8,18.16,164.4,14.92,167.04,14.2Zm-16.8,0-.12-1.08c-8.64,2.52-18.36,8.28-18.36,17.04a6.348,6.348,0,0,0,6.24,6.6c3.96,0,6.84-2.52,6.84-6.12,0-3-1.56-6.24-6-6.72a4.235,4.235,0,0,1,.36-1.68C141,18.16,147.6,14.92,150.24,14.2Z"
              transform="translate(-131.76 -13.12)"
              fill="#d40000"
            />
          </svg>

          <p className="content">{story.content}</p>
          {/* <Horizontal /> */}
          {/* <Horizontal /> */}
          <p className="byline">
            – <span>{story.author}</span>
          </p>
          {/* <button onClick={clickFunction}>Test button here</button> */}
          {/* </div> */}
        </div>

        {/* {!isLast && (
          <div className="downs">
            <DownButton />
          </div>
        )} */}
        {isLast && (
          <div className="story cta">
            <h2>More memories</h2>
            <p>Join us on new expeditions and create new life lasting memories together.</p>
            <a className="book-button" href="https://www.hurtigruten.com/" target="_blank">
              Go explore
            </a>
          </div>
        )}
      </div>
    </StoryStyle>
  );
}
