import React from 'react';
import styled from 'styled-components';

const HeaderStyle = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  /* height: 40px; */
  background: white;
  top: 0;
  padding: 0.7em 0;
  text-align: center;
  img {
    max-height: 36px;
  }
`;
export default function Header() {
  return (
    <HeaderStyle>
      <img src="https://cdn.sanity.io/images/kz42dfs4/production/20df309ea6ea18557fa881fa1b5bdfab74b48fa0-970x233.png" />
    </HeaderStyle>
  );
}
