import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import styled from 'styled-components';

const DownButtonStyle = styled.button`
  border-radius: 50%;
  background: #d40000;
  border: 0;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 30px;
  padding-top: 10px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  z-index: 30;
  &:hover {
    /* font-size: 32px; */
    padding-top: 14px;
  }
`;

const scrollNext = refName => {
  // If scroll position is 0, scroll less aggressively.

  // Get the current y position
  let currentY = window.pageYOffset;
  // Get the window height
  const viewPortHeight = window.innerHeight - 61;

  // On click set scrollto the current position plus the viewport height.
  const desiredNewPosition =
    currentY === 0 ? currentY + viewPortHeight - 150 : currentY + viewPortHeight;

  // New regime. Try moving pushing down exactly the height of the element.

  const newPos = currentY + viewPortHeight;

  // What we want to do is stack all the elements on top of each other and move to the top of each next
  // one upon click.

  // Where is the total height accumulated? In the story container. I'm passing it down there

  // stepFunction(newPos);

  window.scrollTo({
    top: newPos,
    behavior: 'smooth',
  });
};

// export default function DownButton({ elementHeight, stepFunction }) {
export default function DownButton() {
  // console.log(stepFunction);
  // console.log({ elementHeight });
  const [scrollPosition, setscrollPosition] = useState(0);

  return (
    <DownButtonStyle onClick={scrollNext}>
      <BsChevronDown />
    </DownButtonStyle>
  );
}
