import React, { useState } from 'react';
import Story from './Story';
import styled from 'styled-components';
import IntroBlock from './IntroBlock';
const StoryContainerStyle = styled.div`
  position: relative;
  margin-top: 76px; // 40px plus 2 x 1em
`;
export default function StoryContainer({ stories, headerHeight }) {
  // Shift the thing down

  // Staale Start heres

  const storyLength = stories.length;

  // let allHeights = [];

  const [allHeights, setAllHeights] = useState([]);

  function addToHeight(incomingHeight) {
    // Add incoming height to allheights
    // console.log('adding', incomingHeight);
    // allHeights.push(incomingHeight);
    // if (allHeights.length > storyLength) {
    //   resetHeights();
    // } else {
    //   setAllHeights([...allHeights, incomingHeight]);
    // }
    // console.log(allHeights);
  }

  // If the length heights is longer than the number of stories

  function accumulateHeight(value) {
    console.log('you clicked, Sir?', value);

    // Create an array of the heights of all the elements
  }

  function resetHeights() {
    // // resetting
    // console.log('reset', allHeights.length);
    // setAllHeights([]);
  }
  return (
    <StoryContainerStyle>
      <IntroBlock headerHeight={headerHeight} />
      {stories.map((i, index) => {
        // If this is the last one, send last as true
        const isLast = stories.length - 1 === index ? true : false;
        return (
          <Story
            className="lippy"
            key={i._id}
            story={i}
            isLast={isLast}
            stepFunction={accumulateHeight}
            accumulateFunction={addToHeight}
            resetHeights={resetHeights}
            index={index}
          />
        );
      })}
    </StoryContainerStyle>
  );
}
