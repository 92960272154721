/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { object, shape } from 'prop-types';
import { graphql } from 'gatsby';
// import SEO from '../cpt/SEO';
import SanityClientConstructor from '@sanity/client';
import styled from 'styled-components';
import StoriesPage from '../cpt/Pages/StoriesPage';
import { Helmet } from 'react-helmet';
// import Storisten from './../cpt/Pages/StoriesPage'

export default function pageTemplate(props) {
  // Here there will be information about what type of page this is. A switch statement selecting which top level component to load.

  return (
    <div>
      <Helmet>
        <title>Hurtigruten Memories</title>
        <meta property="title" content="Hurtigruten Memories" />
        <meta property="type" content="website" />
        <meta property="og:type" content="website" />
        <meta property="twitter:type" content="website" />
        <meta property="og:title" content="Hurtigruten Memories" />
        <meta property="twitter:title" content="Hurtigruten Memories" />
        <meta
          property="description"
          content="We invited you to share your best Hurtigruten memory and the response was overwhelming. Here is a small sample of all the memories we have received."
        />
        <meta
          property="og:description"
          content="We invited you to share your best Hurtigruten memory and the response was overwhelming. Here is a small sample of all the memories we have received."
        />
        <meta
          property="twitter:description"
          content="We invited you to share your best Hurtigruten memory and the response was overwhelming. Here is a small sample of all the memories we have received."
        />
        <meta property="image" content="" />}
        <meta property="og:image" content="" />}
        <meta property="twitter:image" content="" />}
        {<script async src="https://www.googletagmanager.com/gtag/js?id=G-XQ7ZCBWVPF"></script>}
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-XQ7ZCBWVPF');`}
        </script>{' '}
        }
      </Helmet>
      <StoriesPage props={props} />
    </div>
  );
}

// We need a data structure. Could certainly host it at Sanity.

// export const sanityPageQuery = graphql`
//   query SanityQuizTemplateQuery($id: String!) {
//     quiz: sanityQuiz(_id: { eq: $id }) {
//       displayTitle
//       _id
//       _key
//       active
//       meta {
//         ogUrl
//         ogTitle
//         ogDescription
//         ogImage {
//           _key
//           asset {
//             url
//           }
//         }
//       }
//     }
//   }
// `;
